.experience-wrap {
  position: relative;
}

.experience-wrap .shape-1 {
  /* position: absolute; */
  /* left: 102px; */
  top: -28px;
  -webkit-animation: round-01 30s linear infinite;
          animation: round-01 30s linear infinite;
  z-index: -1;
}

 .experience-wrap .experience {
  width: 370px;
  height: 370px;
  background: linear-gradient(90deg, #693eaa 0%, #01ebf2 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  padding: 130px 90px;
  position: absolute;
  left: 48px;
  top: 40px;
}

 .experience-wrap .experience .number {
  font-size: 170px;
  line-height: 54px;
  font-weight: 600;
  color: #ffffff;
}

 .experience-wrap .experience span {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  color: #ffffff;
  margin-top: 60px;
  padding-left: 10px;
}



 .service-content .text {
  font-size: 18px;
  line-height: 30px;
}
@keyframes round-01 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 449px)
{
 .experience-wrap .shape-1 {
    width: 310px;
    left: 0;
    top: -25px;
}

 .experience-wrap .experience {
    width: 252px;
    height: 241px;
    padding: 75px 42px;
    left: 21px;
    top: 39px;
}
.experience-wrap .experience span {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    color: #ffffff;
    margin-top: 20px;
    padding-left: 10px;
}

.experience-wrap .experience .number {
    font-size: 120px;
}
}

@media only screen and (max-width: 767px)
{
.experience-wrap .shape-1 {
    left: 30px;
    top: -50px;
}
}

