.even-slide
{
    background:#EEF9FF!important;
}
.even-slide .card{
    background:none;
}
.spondiasteam
{
    padding:40px;
}

.slick-current .active-team
{
    background:#ffffff !important;
        box-shadow: 0 0 30px #ddd;
}
.team-light
{
    background-color:#EEF9FF !important;
}


.slick-dots li button {
              width: 15px;
              height: 15px;
              border-radius: 4px;
              background-color: #000;
              opacity: 0.5; 
            }

            .slick-dots li.slick-active button:before {
              background-color:#06a3da;
               width: 15px;
              height: 15px;
              /* opacity: 1; */
            }
            .slick-dots li button:before
            {
                content:'' !important;
            }