
.topnav{
   
    width: content-auto;
    border-radius: 0px 0px 30px 31px;
    margin-right: 0px;
    padding:0px 20px;
}

.iconslist {
    text-decoration: none;
    color: white;
    font-size: 13px;
    font-weight: 500;
    padding: 0px 5px;
    padding-bottom: 5px;
    line-height: 20px;
    
}
.nav-link{
    font-weight: 600 !important;
}
 .active, .active:hover, .nav-link:hover, .dropdown-item:hover {
    color: #11ddf5 !important;
    font-weight: 600 !important;
}


.top_menu
{
 background: #030213;
}
.header-top-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0;
    padding-bottom: 4px;
}
.header-top-wrapper .header-social span {
    font-size: 14px;
    color: #11ddf5;
    display: inline-block;
    margin-right: 20px;
}
.header-top-wrapper .header-social ul li {
    display: inline-block;
}
.header-top-wrapper .header-social ul{
    padding:0px;
    margin:0px;
}
.header-top-wrapper .header-social ul li a {
    display: inline-block;
    font-size: 13px;
    color: #8c8c8c;
    padding-left: 15px;
}
.header-top-wrapper .header-top-info ul li {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    position: relative;
    padding:0px;
    margin:0px;
}
.header-top-wrapper .header-top-info ul li i {
    font-size: 20px;
    color: #11ddf5;
    position: relative;
    top: 1px;
    margin-right: 7px;
}
.header-top-wrapper .header-top-info ul li + li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    background: #353542;
    width: 1px;
    height: 45px;
}
ul, ol {
    padding: 0;
    list-style: none;
    margin: 0 !important;
}
.header-top-section {
    background: #030213;
}
.header-top-wrapper .header-top-info ul li + li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 40%;
    background: #353542;
    width: 1px;
    height: 27px;
    transform: translateY(-46%);
}
.header-top-wrapper .header-social span {
    font-size: 14px;
    color: #11ddf5;
    display: inline-block;
    margin-right: 0px;
}
.header-top-wrapper .header-social ul li {
    display: inline-block;
}
.header-top-wrapper .header-social {
    display: flex;
    align-items: center;
}

#hero .aos-animate
{
    color:#fff;
}
#hero p
{
    color:#11ddf5;
}
.btns {
  display: inline-block;
  overflow: hidden;
  transform: perspective(0) translateZ(0);
  background: #11ddf5;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  border-color: transparent;
  color: #ffffff;
  border-radius: 5px;
  font-size: 18px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  height: 57px;
  line-height: 56px;
  padding: 0px 70px;
  border: 0;
  border-style: solid;
  box-shadow: none;
  transition: all 0.5s ease 0s;
  cursor: pointer;
  z-index: 1;
  position: relative;
  text-decoration:none;
}

.btns::before {
  content: "";
  background: #0e0e0e;
  height: 50%;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: -1;
}

.btns::after {
  content: "";
  background: #0e0e0e;
  height: 50%;
  width: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  left: auto;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: -1;
}

.btns:focus, .btns:focus:active {
  box-shadow: none;
}

.btns:hover {
  color: #ffffff;
}

.btns:hover::before {
  width: 100%;
  right: 0;
  left: auto;
}

.btns:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}
