.noUnderline {
    text-decoration: none;
  }

.link-animated .text-light {
    transition: 0.5s;
}
.bg-footer, .btn-primary
{
  background:#06A3DA!important;
}
.text-clr
{
  color:#06A3DA!important;
}
.section-title::before {
    position: absolute;
    content: "";
    width: 150px;
    height: 5px;
    left: 0;
    bottom: 0;
    background: #06A3DA !important;
    border-radius: 2px
}
.section-title.text-center::before {
    left: 50%;
    margin-left: -75px
}

.section-title.section-title-sm::before {
    width: 90px;
    height: 3px
}

.section-title.text-center::before {
    left: 50%;
    margin-left: -75px;
}

.section-title.section-title-sm::before {
    width: 90px;
    height: 3px;
}

.section-title::after {
    position: absolute;
    content: "";
    width: 6px;
    height: 5px;
    bottom: 0;
    background: #fff;
}

.section-title.section-title-sm::after {
    width: 4px;
    height: 3px;
}

.section-title.text-center::after {
    animation: section-title-run-center 5s infinite linear;
}

.section-title.section-title-sm::after {
    animation: section-title-run-sm 5s infinite linear;
}

@keyframes section-title-run-center {
    0% {
        left: 50%;
        margin-left: -75px
    }
    50% {
        left: 50%;
        margin-left: 45px
    }
    100% {
         left: 50%;
        margin-left: -75px
    }
}

@keyframes section-title-run-sm {
     0% {
        left: 0
    }

    50% {
        left: 85px
    }

    100% {
        left: 0
    }
}
