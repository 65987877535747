h1
{
    font-weight:800 !important;
}
.text
{
    text-align:justify;
}
.testimonial-item > div:nth-child(2) {
    text-align: justify;
}