.ourservices .row .card
{
    border-radius:0px !important; 
    box-shadow:none !important;
    background:#EEF9FF!important;
    border:0px;
    padding:30px 0px !important;
}
.ourservices .row .card p
{
    font-size: 13px;
    letter-spacing: 0.5px;
    line-height: 20px;
}
.service-icon {
    margin-bottom: 30px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #06a3da;
    border-radius: 2px;
    transform: rotate(-45deg);
    margin:auto;
    margin-bottom: 30px;
}
.service-icon i {
    transform: rotate(45deg)
}
.service-icon img
{
    transform: rotate(45deg)
}
.ourservices
{
    padding:20px;
}
.ourservices .row .card a
{
    background: none;
    border: 1px solid rgb(41, 169, 226);
    color: rgb(41, 169, 226) !important;
    padding: 5px 10px;
    font-size: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius:3px;
}
.text-primary {
    color: #06A3DA !important;
}
.slick-slide img{
    width:120px;
    height: auto;
}
.whats-news
{
   background:#ffffff; 
   text-align:center;
}

.whats-news  p {
    font-size: 13px;
    letter-spacing: 0.5px;
    line-height: 20px;
}
.whats-news a {
    background: none;
    border: 1px solid rgb(41, 169, 226);
    color: rgb(41, 169, 226) !important;
    padding: 5px 10px;
    font-size: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 3px;
}
.whats-newsection
{
    background: #e2e2e24a;
    padding: 20px 0px;
}
#hero
{
    background: url('../../assets/hero/hero-bg6.jpg');
    padding:20px;
}
.header-top-info ul{
    padding:0px;
}



.ourservices .rounded-lg {
  /* display: inline-block;
  overflow: hidden; */
  transform: perspective(0) translateZ(0);
  background: #11ddf5;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  border-color: transparent;
  /* color: #ffffff;
  border-radius: 5px;
  font-size: 18px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  height: 57px;
  line-height: 56px;
  padding: 0px 70px;
  border: 0;
  border-style: solid;
  box-shadow: none; */
  transition: all 0.5s ease 0s;
  /* cursor: pointer;
  z-index: 1;
  position: relative;
  text-decoration:none; */
}

.ourservices .rounded-lg::before {
  content: "";
  background: #006ba6;
  height: 50%;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: -1;
}

.ourservices .rounded-lg::after {
  content: "";
  background: #006ba6;
  height: 50%;
  width: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  left: auto;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: -1;
}

.ourservices .rounded-lg:hover::before {
  width: 100%;
  right: 0;
  left: auto;
}

.ourservices .rounded-lg:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

/* .btns:focus, .btns:focus:active {
  box-shadow: none;
}

.btns:hover {
  color: #ffffff;
}

.btns:hover::before {
  width: 100%;
  right: 0;
  left: auto;
}

.btns:hover::after {
  width: 100%;
  left: 0;
  right: auto;
} */