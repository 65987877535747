.contact
{
        background: #e2e2e259;
}
.contact-box
{
        background: #fff;
    padding: 20px 40px;
}
.bg-color{
        background:rgb(6 163 218) !important;
}